window.openShareDialog = function (url, w, h) {
    window.open(url + encodeURIComponent(location.href), 'ShareDialog', 'width=' + w + ',height=' + h);
    return false;
}

window.CopyLink = (e) => {
    let dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    $('#copy-alert').fadeIn();

    setTimeout(hideCopyAlert, 3000);
}

$(function () {
    $('#copy-alert a').on('click', (e) => {
        hideCopyAlert()
    })

    window.fixLiftBlockMargins();

    $(window).resize(window.fixLiftBlockMargins);

    // This fixes liftblocks which content comes from sst's scripts
    setTimeout(function () {
        window.fixLiftBlockMargins();
    }, 500);

    var hideCopyAlert = () => {
        $('#copy-alert').fadeOut();
    }
})

window.fixLiftBlockMargins = () => {
    $('.liftblock-bg').each((i, e) => {
        fixLiftBlock(e);
    });

    function fixLiftBlock(block) {
        var liftblockHeight = $(block).innerHeight();
        var liftblockContentHeight = $(block).children('.liftblock-content').innerHeight();

        if ($(window).width() > 1199) {
            $(block).css('margin-bottom', liftblockContentHeight - liftblockHeight + 60);
        }
        else if ($(window).width() > 991) {
            $(block).css('margin-bottom', liftblockContentHeight - liftblockHeight + 90);
        }
        else {
            $(block).css('margin-bottom', liftblockContentHeight - 100);
        }
    }
}

window.initAutocomplete = function (element) {
    element.autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "/find_v2/_autocomplete?prefix=" + encodeURIComponent(request.term) + "&size=5",
                success: function (data) {
                    response($.map(data.hits, function (item) {
                        return {
                            label: item.query,
                            value: item.query
                        };
                    }));
                }
            });
        },
        classes: {
            'ui-autocomplete': 'autocomplete-' + element.attr('id')
        },
        minLength: 2,
        select: function (e, ui) {
            element.val(ui.item.value).parent().submit();
        }
    });
}

window.findNearestPharmacy = (query) => {
    let url = 'https://www.google.com/maps/search/apteekit';

    if (query)
        url += '+in+' + query.replace(' ', '+');

    window.open(url, '_blank');
    //} else {
    //    if (navigator.geolocation) {
    //        navigator.geolocation.getCurrentPosition(function (position) {
    //            url = `${url}/@${position.coords.latitude},${position.coords.longitude}z`;
    //            window.open(url, '_blank');
    //        })
    //    } else {
    //        alert('Selaimesi ei tue geolokaatiota.');
    //    }
    //}
}


$(document).ready(function () {
    $('.accordion-title').click(function () {
        $(this).toggleClass('open').next('.accordion-content').slideToggle();
        if ($(this).hasClass('open')) {
            $(this).attr('aria-expanded', true);
        } else {
            $(this).attr('aria-expanded', false)
        }
    });
});